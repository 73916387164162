import { useEffect, useRef } from 'react';

// Make sure we start the video on last scroll position
export function useSeekYouTubeVideoToInitialProgress({
  scrollDepth,
  seekTo,
  videoDuration,
  isPlaying,
}: {
  scrollDepth?: number | null;
  seekTo?: (seconds: number) => void;
  videoDuration?: number;
  isPlaying: boolean;
}) {
  const initialSeekDone = useRef(false);

  useEffect(() => {
    if (!scrollDepth || !videoDuration || initialSeekDone.current || !seekTo || !isPlaying) {
      return;
    }

    const initialSeekTo = scrollDepth * videoDuration;
    seekTo(initialSeekTo);
    initialSeekDone.current = true;
  }, [scrollDepth, videoDuration, seekTo, isPlaying]);
}
